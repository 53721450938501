import React, { useCallback, useMemo, useState } from "react";
import { useActions, useReduxState } from "re-reduced";
import actions from "domain/core/app/actions";
import {
  getAvailableProducts,
  getAvailableProductsStatus,
  getDisabledCustomisationItems,
  getDisabledCustomisationItemsRequestState,
  getDisabledItems,
  getDisabledItemsRequestState,
} from "domain/selectors";
import { REQUEST_STATUS } from "lib/types";

import { ProductTab } from "domain/core/app/types";
import styled from "styled-components";
import { LoadingOverlay } from "./LoadingOverlay";
import {
  ActionPendingConfirmation,
  ConfirmChangeStockAvailabilityModal,
} from "./ConfirmChangeStockAvailabilityModal";
import ProductSectionList from "./ProductSectionList";

const Container = styled.div`
  flex: 1;
  display: flex;
  flex-direction: row;
  padding: 20px;
`;

const unboundActions = {
  updateProductAvailability: actions.updateProductAvailability,
  updateCustomisationAvailability: actions.updateCustomisationAvailability,
};

const stateSelectorMap = {
  disabledItems: getDisabledItems,
  disabledItemsRequestState: getDisabledItemsRequestState,
  disabledCustomisationItems: getDisabledCustomisationItems,
  disabledCustomisationItemsRequestState: getDisabledCustomisationItemsRequestState,
  availableProductsRequestState: getAvailableProductsStatus,
  availableProducts: getAvailableProducts,
};

interface Props {
  productTab: ProductTab;
}

function ProductAvailabilityManager({ productTab }: Props) {
  const actions = useActions(unboundActions);
  const state = useReduxState(stateSelectorMap);

  const isLoading =
    state.disabledItemsRequestState === REQUEST_STATUS.Pending ||
    state.disabledCustomisationItemsRequestState === REQUEST_STATUS.Pending ||
    state.availableProductsRequestState === REQUEST_STATUS.Pending;

  const items = useMemo(() => {
    return state.availableProducts.filter(
      (product) => product.tab === productTab
    );
  }, [state.availableProducts, productTab]);

  const [actionPendingConfirmation, setActionPendingConfirmation] = useState<
    ActionPendingConfirmation
  >();

  const handleConfirmDialogClose = useCallback(() => {
    setActionPendingConfirmation(undefined);
  }, []);

  const handleConfirmDialogConfirm = useCallback(() => {
    if (actionPendingConfirmation) {
      const { item, disableOrEnable, type } = actionPendingConfirmation;

      if (type) {
        actions.updateCustomisationAvailability({
          productId: item.productId,
          disableOrEnable: disableOrEnable,
          isTemporary:
            type === "stock"
              ? disableOrEnable === "enable"
              : disableOrEnable === "disable",
        });
      } else {
        actions.updateProductAvailability({
          productIds: [actionPendingConfirmation.item.productId],
          disableOrEnable: actionPendingConfirmation.disableOrEnable,
        });
      }
    }

    handleConfirmDialogClose();
  }, [actionPendingConfirmation, actions, handleConfirmDialogClose]);

  const handleActionPendingConfirmation = useCallback(
    (actionPendingConfirmation: ActionPendingConfirmation) => {
      setActionPendingConfirmation(actionPendingConfirmation);
    },
    []
  );

  return (
    <Container>
      <LoadingOverlay isLoading={isLoading} />
      {items.map((item) => (
        <ProductSectionList
          key={item.label}
          item={item}
          isLoading={isLoading}
          disabledItems={state.disabledItems}
          disabledCustomisationItems={state.disabledCustomisationItems}
          handleActionPendingConfirmation={handleActionPendingConfirmation}
        />
      ))}
      <ConfirmChangeStockAvailabilityModal
        actionPendingConfirmation={actionPendingConfirmation}
        onClose={handleConfirmDialogClose}
        onConfirm={handleConfirmDialogConfirm}
      />
    </Container>
  );
}

export default ProductAvailabilityManager;
