import apiClient from "lib/apiClient/api";
import logginClient from "lib/apiClient/logging";

import { ENDPOINTS } from "domain/core/configuration";
import {
  SiteAvailability,
  LogEvent,
  InventoryStateResponse,
  CustomisationStateResponse,
  DisableCustomisationItem,
  ProductResponse,
} from "domain/core/app/types";

import { getNow } from "lib/date-time";
import { getAllRemoteConfig } from "lib/firebase";
import { indexBy } from "ramda";

export const updateSiteAvailability = apiClient.patch<
  SiteAvailability,
  SiteAvailability
>(ENDPOINTS.sitesStatus);

export const fetchPreorderStatus = apiClient.get<SiteAvailability>(
  ENDPOINTS.sitesStatus
);

export const logEvent = logginClient.post<void, LogEvent>("");

export function semverGreaterThan(
  latestVersion: string,
  currentVersion: string
) {
  const latestParts = latestVersion.split(/\./g);
  const currentParts = currentVersion.split(/\./g);

  while (latestParts.length || currentParts.length) {
    const a = Number(latestParts.shift());
    const b = Number(currentParts.shift());

    if (a === b) continue;

    return a > b || isNaN(b);
  }

  return false;
}

/**
 * Wraps fetch with a concurrent timeout-based callback
 *
 * @param {number} timeout  - timeout in milliseconds
 * @param {Function} [onTimeout] - timeout callback
 */
export const fetchWithTimeout = (
  timeout: number,
  onTimeout: () => void = () => {
    throw new Error("Timeout exceeded");
  }
) => async (url: string) => {
  // this forces a 5s timeout in case the request hangs
  const timeoutHandle = window.setTimeout(onTimeout, timeout);
  const response = await fetch(url);
  // cancels the timeout once the request is resolved
  window.clearTimeout(timeoutHandle);
  return response;
};

export async function purgeCache() {
  if (window.caches) {
    const keys = await window.caches.keys();
    const deleteKeys = keys.map((key) => window.caches.delete(key));

    await Promise.all(deleteKeys);
  }
}

export async function checkForAppUpdates() {
  const onTimeout = () => {
    throw new Error("Timout reached while fetching meta.json");
  };

  const fetchWithTimeoutHandler = fetchWithTimeout(5000, onTimeout);

  const response = await fetchWithTimeoutHandler(`/meta.json?t=${getNow()}`);
  const meta = await response.json();
  const latestVersion = meta.version;
  const currentVersion = global.appVersion;

  return semverGreaterThan(latestVersion, currentVersion);
}

export const fetchInventoryState = apiClient.get<InventoryStateResponse>(
  `${ENDPOINTS.inventory}/disabled`
);

export const disableProducts = apiClient.post<InventoryStateResponse, string[]>(
  `${ENDPOINTS.inventory}/disable`
);

export const enableProducts = apiClient.post<InventoryStateResponse, string[]>(
  `${ENDPOINTS.inventory}/enable`
);

export const fetchCustomisationState = apiClient.get<
  CustomisationStateResponse
>(`${ENDPOINTS.inventory}/customisation/disabled`);

export const enableCustomisation = apiClient.post<
  CustomisationStateResponse,
  string[]
>(`${ENDPOINTS.inventory}/customisation/enable`);

export const disableCustomisation = apiClient.post<
  CustomisationStateResponse,
  DisableCustomisationItem[]
>(`${ENDPOINTS.inventory}/customisation/disable`);

export async function fetchFirebaseRemoteConfig() {
  try {
    const response = await getAllRemoteConfig();

    const indexed = indexBy((x) => x.key, response);
    return indexed;
  } catch (error) {
    console.log(`failed to fetch firebase config:`, (error as Error).message);
    return [];
  }
}

export const fetchProducts = apiClient.get<ProductResponse>(
  `${ENDPOINTS.inventory}/products`
);
