import React, { useState, useEffect, useMemo, useCallback } from "react";
import styled from "styled-components";
import { grey } from "@material-ui/core/colors";
import {
  getAvailableProducts,
  getDisabledCustomisationItems,
  getDisabledItems,
  getVeganEnabledRemoteConfig,
} from "domain/selectors";
import { useReduxState } from "re-reduced";
import WarningIcon from "@material-ui/icons/Warning";
import {
  AvailableProduct,
  DisabledItem,
  PRODUCT_TYPE,
  ProductTab,
} from "domain/core/app/types";
import { isBefore } from "date-fns";
import { useHistory } from "react-router";

import { isEmpty } from "ramda";

const Container = styled.div`
  position: absolute;
  z-index: 99;
  bottom: 0;
  left: 0;
  right: 0;
  height: 65px;
  border-top: 1px solid ${grey[200]};
  background-color: white;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding-left: 20px;
  cursor: pointer;
`;
const DisabledText = styled.div`
  font-weight: bold;
  margin-left: 20px;
  margin-right: 10px;
`;

const stateSelectorMap = {
  disabledItems: getDisabledItems,
  veganSmoothieEnabled: getVeganEnabledRemoteConfig,
  disabledCustomisationItems: getDisabledCustomisationItems,
  availableProducts: getAvailableProducts,
};

function getProductIds(products: AvailableProduct[], tabName: ProductTab) {
  return products
    .filter((product) => product.tab === tabName)
    .flatMap((product) => product.products.map((p) => p.productId));
}

const findOldestDisabledAtDate = (disabledItems: DisabledItem[]) =>
  disabledItems.reduce((prev: Date, curr: DisabledItem) => {
    if (isBefore(new Date(curr.since), prev)) {
      return new Date(curr.since);
    }
    return prev;
  }, new Date());

export function DisabledItemsBar() {
  const state = useReduxState(stateSelectorMap);
  const [now, setNow] = useState(new Date());
  const history = useHistory();

  const drinksProductIds = useMemo(
    () => getProductIds(state.availableProducts, "drinks"),
    [state.availableProducts]
  );

  const foodProductIds = useMemo(
    () => getProductIds(state.availableProducts, "food"),
    [state.availableProducts]
  );

  const drinkIngredientsProductIds = useMemo(
    () => getProductIds(state.availableProducts, "drinkIngredients"),
    [state.availableProducts]
  );

  const getProductDisabled = useCallback(
    (productIds: string[]) => {
      return state.availableProducts.some((product) => {
        if (product.type === PRODUCT_TYPE.Product) {
          return state.disabledItems.some((item) =>
            productIds.includes(item.productId)
          );
        }

        const isTemporary =
          product.type === PRODUCT_TYPE.CustomisationTemporary;

        return state.disabledCustomisationItems.some(
          (item) =>
            item.isTemporary === isTemporary &&
            productIds.includes(item.productId)
        );
      });
    },
    [
      state.availableProducts,
      state.disabledCustomisationItems,
      state.disabledItems,
    ]
  );

  const anyFoodDisabled = useMemo(() => getProductDisabled(foodProductIds), [
    getProductDisabled,
    foodProductIds,
  ]);

  const anyDrinksDisabled = useMemo(
    () => getProductDisabled(drinksProductIds),
    [getProductDisabled, drinksProductIds]
  );

  const anyDrinkIngredientsAreDisabled = useMemo(
    () => getProductDisabled(drinkIngredientsProductIds),
    [getProductDisabled, drinkIngredientsProductIds]
  );

  const disabledText = [
    anyFoodDisabled ? "Food" : undefined,
    anyDrinksDisabled ? "Drinks" : undefined,
    anyDrinkIngredientsAreDisabled ? "Drink Ingredients" : undefined,
  ]
    .filter((text) => text !== undefined)
    .toString()
    .replace(/,/g, ", ")
    // eslint-disable-next-line
    .replace(/,([^,]*)$/, " &" + "$1");
  useEffect(() => {
    const interval = setInterval(() => setNow(new Date()), 1000 * 60);

    return () => {
      clearInterval(interval);
    };
  }, []);

  if (isEmpty(disabledText) || isEmpty(state.disabledItems)) {
    return null;
  }

  const handleContainerClick = () => {
    if (!anyFoodDisabled && anyDrinksDisabled) {
      history.push("/stockmanager/chilleddrinks");
    } else if (!anyFoodDisabled && anyDrinkIngredientsAreDisabled) {
      history.push("/stockmanager/drinkIngredients");
    } else {
      history.push("/stockmanager");
    }
  };

  const oldestDisabledAtDate = findOldestDisabledAtDate(state.disabledItems);

  let runningDisabledTimeInSeconds = Math.ceil(
    (now.getTime() - oldestDisabledAtDate.getTime()) / 1000
  );

  if (runningDisabledTimeInSeconds < 0) {
    runningDisabledTimeInSeconds = 0;
  }

  const hours = Math.floor(runningDisabledTimeInSeconds / 3600);
  const minutes = Math.floor(
    (runningDisabledTimeInSeconds - hours * 3600) / 60
  );

  return (
    <Container onClick={handleContainerClick}>
      <WarningIcon color="error" fontSize="large" />
      <DisabledText>
        You have disabled items in your stock manager for {disabledText}
      </DisabledText>
      {hours < 10 && "0"}
      {hours}:{minutes < 10 && "0"}
      {minutes}
    </Container>
  );
}
