import React, { useCallback, useMemo } from "react";
import {
  AvailableProduct,
  DisabledItem,
  DisabledCustomisationItem,
  PRODUCT_TYPE,
  Product,
} from "domain/core/app/types";
import StyledSwitch from "ui/components/StyledSwitch";
import { ActionPendingConfirmation } from "./ConfirmChangeStockAvailabilityModal";
import styled from "styled-components";
import { FormControlLabel } from "@material-ui/core";
import { grey } from "@material-ui/core/colors";

const SectionContainer = styled.div`
  position: relative;
  width: 300px;
  margin-right: 20px;
`;

const SectionList = styled.div`
  overflow: scroll;
  max-height: 618px;
  padding-bottom: 65px;
`;

const SectionHeaderContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  border-bottom: 1px solid ${grey[300]};
`;

const SectionHeader = styled.div`
  flex: 1;
  font-size: 20px;
`;

const OptionContainer = styled(FormControlLabel)`
  width: 100%;
  background-color: ${grey[200]};
  border-radius: 5px;
  height: 50px;
  margin-top: 10px;
  display: flex;
  justify-items: center;
  padding-left: 10px;
  margin-left: 0 !important;
  margin-right: 0 !important;
`;

const isProductDisabled = (productId: string, disabledItems: DisabledItem[]) =>
  disabledItems.some((item) => item.productId === productId);

const handleToggleChange = (
  item: Product,
  isLoading: boolean,
  setActionPendingConfirmation: (
    actionPendingConfirmation: ActionPendingConfirmation
  ) => void,
  type: typeof PRODUCT_TYPE[keyof typeof PRODUCT_TYPE]
) => (event: React.ChangeEvent<HTMLInputElement>) => {
  if (isLoading) return;

  const disableOrEnable = event.target.checked ? "enable" : "disable";

  if (type === PRODUCT_TYPE.Product) {
    setActionPendingConfirmation({ item, disableOrEnable });
    return;
  }

  setActionPendingConfirmation({
    item,
    disableOrEnable,
    type:
      type === PRODUCT_TYPE.CustomisationPermanent ? "stock" : "customisation",
  });
};

const isCustomisationItemDisabled = (
  productId: string,
  disabledCustomisationItems: DisabledCustomisationItem[]
) =>
  disabledCustomisationItems.some(
    (item) => item.productId === productId && item.isTemporary
  );

const isCustomisationItemOutOfStock = (
  productId: string,
  disabledCustomisationItems: DisabledCustomisationItem[]
) =>
  disabledCustomisationItems.some(
    (item) => item.productId === productId && !item.isTemporary
  );

const isNoneCustomisationItemStock = (
  disabledCustomisationItems: DisabledCustomisationItem[],
  items: Product[]
) =>
  !items.some(
    (customisationItem) =>
      !isCustomisationItemOutOfStock(
        customisationItem.productId,
        disabledCustomisationItems
      )
  );

const ProductSectionList = ({
  item,
  isLoading,
  disabledItems,
  disabledCustomisationItems,
  handleActionPendingConfirmation,
}: {
  item: AvailableProduct;
  isLoading: boolean;
  disabledItems: DisabledItem[];
  disabledCustomisationItems: DisabledCustomisationItem[];
  handleActionPendingConfirmation: (
    actionPendingConfirmation: ActionPendingConfirmation
  ) => void;
}) => {
  const { label, products, type } = item;

  const isVisible = useMemo(
    () =>
      type !== PRODUCT_TYPE.CustomisationTemporary ||
      !isNoneCustomisationItemStock(disabledCustomisationItems, products),
    [type, disabledCustomisationItems, products]
  );

  const isChecked = useCallback(
    (productId: string) => {
      switch (type) {
        case PRODUCT_TYPE.CustomisationPermanent:
          return !isCustomisationItemOutOfStock(
            productId,
            disabledCustomisationItems
          );
        case PRODUCT_TYPE.CustomisationTemporary:
          return !isCustomisationItemDisabled(
            productId,
            disabledCustomisationItems
          );
        default:
          return !isProductDisabled(productId, disabledItems);
      }
    },
    [disabledItems, disabledCustomisationItems, type]
  );

  const filteredProducts = useMemo(
    () =>
      products.filter(
        ({ productId }) =>
          type !== PRODUCT_TYPE.CustomisationTemporary ||
          !isCustomisationItemOutOfStock(productId, disabledCustomisationItems)
      ),
    [products, type, disabledCustomisationItems]
  );

  const renderSectionList = useCallback(() => {
    return filteredProducts.map(({ productId, label }) => (
      <OptionContainer
        key={productId}
        control={
          <StyledSwitch
            onChange={handleToggleChange(
              { productId, label },
              isLoading,
              handleActionPendingConfirmation,
              type
            )}
            checked={isChecked(productId)}
          />
        }
        label={label}
      />
    ));
  }, [
    filteredProducts,
    handleActionPendingConfirmation,
    type,
    isLoading,
    isChecked,
  ]);

  if (!isVisible) {
    return null;
  }

  return (
    <SectionContainer>
      <SectionHeaderContainer>
        <SectionHeader>{label}</SectionHeader>
      </SectionHeaderContainer>
      <SectionList>{renderSectionList()}</SectionList>
    </SectionContainer>
  );
};

export default ProductSectionList;
