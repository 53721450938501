import React from "react";
import { AppBar, Tabs, Tab } from "@material-ui/core";

import { ROUTES } from "domain/core/configuration";
import { RouteComponentProps, withRouter, Switch, Route } from "react-router";
import ProductAvailabilityManager from "./ProductAvailabilityManager";

type RouteIndex = 0 | 1 | 2;

const ROUTE_TO_INDEX: Record<string, RouteIndex> = {
  [ROUTES.stockManager]: 0,
  [ROUTES.stockManagerHotFood]: 0,
  [ROUTES.stockManagerChilledDrinks]: 1,
  [ROUTES.stockManagerDrinkIngredients]: 2,
};

const INDEX_TO_ROUTE: Record<RouteIndex, string> = {
  0: ROUTES.stockManagerHotFood,
  1: ROUTES.stockManagerChilledDrinks,
  2: ROUTES.stockManagerDrinkIngredients,
};

const getTabIndexFromRoutePath = (path: string) =>
  path in ROUTE_TO_INDEX ? ROUTE_TO_INDEX[path] : 0;

export function StockManager(props: RouteComponentProps) {
  const tabIndex = getTabIndexFromRoutePath(props.location.pathname);

  const handleTabChange = (_: any, index: RouteIndex) => {
    if (index !== tabIndex && index in INDEX_TO_ROUTE) {
      props.history.push(INDEX_TO_ROUTE[index as RouteIndex]);
    }
  };

  return (
    <>
      <AppBar position="sticky" color="default">
        <Tabs
          value={tabIndex}
          onChange={handleTabChange}
          indicatorColor="primary"
          textColor="primary"
          variant="fullWidth"
        >
          <Tab label="FOOD" />
          <Tab label="DRINKS" />
          <Tab label="DRINK INGREDIENTS" />
        </Tabs>
      </AppBar>
      <Switch>
        <Route
          path={ROUTES.stockManagerChilledDrinks}
          render={() => <ProductAvailabilityManager productTab="drinks" />}
        />
        <Route
          path={ROUTES.stockManagerDrinkIngredients}
          render={() => (
            <ProductAvailabilityManager productTab="drinkIngredients" />
          )}
        />
        <Route
          path={ROUTES.stockManager}
          render={() => <ProductAvailabilityManager productTab="food" />}
        />
      </Switch>
    </>
  );
}

export default withRouter(StockManager);
