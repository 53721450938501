import { SagaIterator } from "redux-saga";
import { takeLatest, all, put, call, select, delay } from "redux-saga/effects";
import { apiWorkerFactory, Action } from "re-reduced";

import { setToken } from "lib/apiClient/api";
import { getTimestamp } from "lib/date-time";
import logger from "lib/logger";

import { AUTO_CHECK_FOR_UPDATES_INTERVAL } from "domain/core/app/configuration";
import {
  SnackbarItem,
  LogEvent,
  AppActivityState,
  OnlineStatusChangeReason,
  UpdateProductAvailabilityAction,
  InventoryStateResponse,
  RemoteConfig,
  CustomisationStateResponse,
  UpdateCustomisationAvailabilityAction,
  ProductResponse,
} from "domain/core/app/types";
import { isTokenValidAndNotExpired } from "domain/core/auth/helpers";
import actions from "domain/core/actions";
import * as api from "domain/core/api";
import * as selectors from "domain/core/selectors";
import { refreshOrders, getIsBrowserTabActive } from "domain/orders/sagas";
import { getUserAgent } from "lib/browser";
import { fetchFirebaseRemoteConfig } from "./api";
import { isEmpty } from "ramda";

export default function* AppSagaWatcher(): SagaIterator {
  yield all([
    takeLatest(actions.app.bootstrap.type, bootstrap),
    takeLatest(actions.app.updatePreorderStatus.type, updatePreorderStatus),
    takeLatest(actions.app.enqueueSnackbar.type, enqueueSnackbar),
    takeLatest(actions.app.logEvent.type, logEvent),
    takeLatest(actions.app.updateActivityState.type, appActivityStateWatcher),
    takeLatest(actions.app.inactive.type, appInactive),
    takeLatest(actions.app.restored.type, appRestored),
    takeLatest(actions.app.checkForUpdates.type, checkForUpdates),
    takeLatest(actions.app.installUpdate.type, installUpdate),
    takeLatest(actions.app.bootstrap.success.type, autoCheckForUpdates),
    takeLatest(actions.app.fetchPreorderStatus.type, fetchPreorderStatus),
    takeLatest(actions.app.fetchInventoryState.type, fetchInventoryState),
    takeLatest(
      actions.app.updateProductAvailability.type,
      updateProductAvailability
    ),
    takeLatest(
      actions.app.fetchCustomisationState.type,
      fetchCustomisationState
    ),
    takeLatest(
      actions.app.updateCustomisationAvailability.type,
      updateCustomisationAvailability
    ),
    takeLatest(actions.app.fetchRemoteConfig.type, fetchRemoteConfig),
    takeLatest(actions.app.fetchProducts.type, fetchAvailableProducts),
  ]);
}

/**
 * App initialisation saga, delays rendering screens until completion
 */
export function* bootstrap(): SagaIterator {
  yield put(actions.app.bootstrap.request());

  const token: string | null = yield select(selectors.getToken);

  if (!token) {
    yield put(actions.app.bootstrap.success());
    return;
  }

  if (isTokenValidAndNotExpired(token)) {
    yield call(setToken, token);
  } else {
    yield all([
      put(
        actions.app.logEvent({
          kind: "Warning",
          message: "Token expired. Account logged out on startup.",
        })
      ),
      put(actions.auth.logout()),
      put(actions.app.bootstrap.success()),
    ]);

    return;
  }

  try {
    yield all([
      call(refreshOrders),
      put(actions.app.fetchPreorderStatus()),
      put(actions.app.fetchInventoryState()),
      put(actions.app.fetchCustomisationState()),
      put(actions.app.fetchRemoteConfig()),
      put(actions.app.fetchProducts()),
    ]);

    if (process.env.NODE_ENV === "development") {
      yield delay(1000);
    }

    yield put(actions.app.requestSound());

    yield put(actions.app.bootstrap.success());
  } catch (error) {
    yield put(actions.auth.logout());
    yield put(actions.app.bootstrap.failure(error as Error));
  }
}

/**
 * Fetches meta.json and matches the version against the currently cached app
 * to tell whether the app should be updated
 */
export const checkForUpdates = apiWorkerFactory(
  actions.app.checkForUpdates,
  api.app.checkForAppUpdates,
  {
    *onSuccess(hasUpdates) {
      yield all([
        put(
          actions.app.enqueueSnackbar({
            variant: hasUpdates ? "success" : "info",
            message: hasUpdates
              ? "A new version is available"
              : "You're on the latest version",
            action: hasUpdates
              ? {
                  label: "update",
                  dispatchableAction: actions.app.installUpdate(),
                }
              : undefined,
            duration: hasUpdates ? 5000 : undefined,
          })
        ),
        put(actions.app.checkForUpdates.success(hasUpdates)),
      ]);
    },
  }
);

/**
 * Automatically checks for updates every {AUTO_CHECK_FOR_UPDATES_INTERVAL} ms
 */
export function* autoCheckForUpdates() {
  while (true) {
    yield call(getIsBrowserTabActive);
    yield put(actions.app.checkForUpdates());
    yield put(actions.app.fetchRemoteConfig());
    yield delay(AUTO_CHECK_FOR_UPDATES_INTERVAL);
  }
}

/**
 * Forces window to reload
 */
export function forceReload() {
  window.location.reload();
}

/**
 * Purges CacheStorage and forces window to reload
 */
export function* installUpdate() {
  try {
    yield call(api.app.purgeCache);
  } catch (error) {
    yield all([
      call(logger.error, "Failed to purge PWA cache"),
      put(
        actions.app.logEvent({
          kind: "Error",
          message: "Failed to purge PWA cache",
          info: error,
        })
      ),
    ]);
  }

  // delete browser cache and hard reload
  yield call(forceReload);
}

/**
 * App is inactive
 */
export function* appInactive(): SagaIterator {
  yield call(logger.info, "App is inactive.");
  // yield put(actions.app.updateActivityState("Inactive"));
}

/**
 * App was restored
 */
export function* appRestored(): SagaIterator {
  yield call(logger.info, "App was restored.");
  yield put(actions.app.checkForUpdates());
  // yield put(actions.app.updateActivityState("Active"));
}

/**
 * Fetches site's preorder availability status
 */
export const fetchPreorderStatus = apiWorkerFactory(
  actions.app.fetchPreorderStatus,
  api.app.fetchPreorderStatus,
  {
    *onFailure(error) {
      yield all([
        put(actions.app.fetchPreorderStatus.failure(error)),
        put(
          actions.app.enqueueSnackbar({
            message: "Failed to fetch site status.",
            variant: "error",
          })
        ),
      ]);
    },
    *onSuccess(result) {
      yield put(
        actions.app.fetchPreorderStatus.success({ online: result.online })
      );
    },
  }
);

/**
 * Updates site's preorder availability status
 */
export const updatePreorderStatus = apiWorkerFactory(
  actions.app.updatePreorderStatus,
  api.app.updateSiteAvailability,
  {
    *onSuccess(result, action) {
      const isOnline = yield select(selectors.getIsSiteOnline);

      yield all([
        put(
          actions.app.updatePreorderStatus.success({
            ...result,
            reason: action.payload.reason,
          })
        ),
        put(
          actions.app.logEvent({
            kind: "Info",
            message: `Site is now ${isOnline ? "disabled" : "enabled"}.`,
            info: action.payload.reason
              ? `Reason: ${action.payload.reason}`
              : undefined,
          })
        ),
        put(
          actions.app.enqueueSnackbar({
            message: `Site is now ${isOnline ? "disabled" : "enabled"}.`,
            variant: isOnline ? "error" : "success",
            persist: isOnline,
            clearPersistedSnacks: !isOnline,
          })
        ),
      ]);
    },
    *onFailure(error) {
      yield all([
        put(actions.app.updatePreorderStatus.failure(error)),
        put(
          actions.app.enqueueSnackbar({
            message: "Failed to update site status.",
            variant: "error",
          })
        ),
      ]);
    },
  }
);

/**
 * Enriches with id and queues a snackbar message
 *
 * @param {Action<SnackbarItem>} action
 */
export function* enqueueSnackbar(action: Action<SnackbarItem>): SagaIterator {
  let id: string = yield select(getTimestamp);

  if (action.payload.id) {
    id = action.payload.id;
  }

  yield put(
    actions.app.enqueueSnackbar.success({
      ...action.payload,
      id,
    })
  );
}

/**
 * Remote event logging
 *
 * @param {Action<LogEvent>} action
 */
export function* logEvent(action: Action<LogEvent>): SagaIterator {
  yield put(actions.app.logEvent.request());

  try {
    const siteName: string = yield select(selectors.getSiteName);
    const siteId: string = yield select(selectors.getSiteId);
    const appVersion: string = yield select(selectors.getAppVersion);
    const userAgent: string | undefined = yield call(getUserAgent);

    const enrichedEventPayload: LogEvent = {
      ...action.payload,
      siteName,
      siteId,
      appVersion,
      userAgent,
    };

    yield call(api.app.logEvent, enrichedEventPayload);
    yield put(actions.app.logEvent.success());
  } catch (error) {
    yield put(actions.app.logEvent.failure(error as Error));
  }
}

/**
 * Monitors activity state changes
 *
 * @param {Action<AppActivityState>} action
 */
export function* appActivityStateWatcher(
  action: Action<AppActivityState>
): SagaIterator {
  const activityState = action.payload;
  const isOnline: boolean = yield select(selectors.getIsSiteOnline);
  const reason: OnlineStatusChangeReason = yield select(
    selectors.getOnlineStatusChangeReason
  );

  switch (activityState) {
    case "Active":
      if (!isOnline && reason === "Inactive") {
        yield put(actions.app.updatePreorderStatus({ online: true }));
      }
      break;
    case "Inactive":
      if (isOnline) {
        yield put(
          actions.app.updatePreorderStatus({
            online: false,
            reason: "Inactive",
          })
        );
      }
      break;
  }
}

export const fetchInventoryState = apiWorkerFactory(
  actions.app.fetchInventoryState,
  api.app.fetchInventoryState,
  {
    *onSuccess(result) {
      yield put(actions.app.fetchInventoryState.success(result));
    },
    *onFailure(error) {
      yield put(actions.app.fetchInventoryState.failure(error));
    },
  }
);

export function* updateProductAvailability(
  action: Action<UpdateProductAvailabilityAction>
): SagaIterator {
  const { productIds, disableOrEnable } = action.payload;

  const apiCall =
    disableOrEnable === "enable"
      ? api.app.enableProducts
      : api.app.disableProducts;

  try {
    yield put(actions.app.updateProductAvailability.request());

    const updatedInventoryState: InventoryStateResponse = yield call(
      apiCall,
      productIds
    );

    yield put(
      actions.app.updateProductAvailability.success(updatedInventoryState)
    );
  } catch (error) {
    yield put(actions.app.updateProductAvailability.failure(error as Error));
  }
}

export const fetchCustomisationState = apiWorkerFactory(
  actions.app.fetchCustomisationState,
  api.app.fetchCustomisationState,
  {
    *onSuccess(result) {
      yield put(actions.app.fetchCustomisationState.success(result));
    },
    *onFailure(error) {
      yield put(actions.app.fetchCustomisationState.failure(error));
    },
  }
);

export function* updateCustomisationAvailability(
  action: Action<UpdateCustomisationAvailabilityAction>
): SagaIterator {
  const { productId, disableOrEnable, isTemporary } = action.payload;

  const apiCall =
    disableOrEnable === "enable"
      ? api.app.enableCustomisation
      : api.app.disableCustomisation;

  const requestParams: any =
    disableOrEnable === "enable"
      ? [productId]
      : [{ productId: productId, isTemporary: isTemporary }];
  try {
    yield put(actions.app.updateCustomisationAvailability.request());

    const updatedCustomisationState: CustomisationStateResponse = yield call(
      apiCall,
      requestParams
    );

    yield put(
      actions.app.updateCustomisationAvailability.success(
        updatedCustomisationState
      )
    );
  } catch (error) {
    yield put(
      actions.app.updateCustomisationAvailability.failure(error as Error)
    );
  }
}

export function* fetchRemoteConfig(): SagaIterator {
  yield put(actions.app.fetchRemoteConfig.request());

  try {
    const remoteConfigResponse: RemoteConfig = yield call(
      fetchFirebaseRemoteConfig
    );
    yield put(actions.app.fetchRemoteConfig.success(remoteConfigResponse));
  } catch (error) {
    yield put(actions.app.fetchRemoteConfig.failure(error as Error));
  }
}

export function* fetchAvailableProducts(): SagaIterator {
  yield put(actions.app.fetchProducts.request());

  try {
    const availableProductResponse: ProductResponse = yield call(
      api.app.fetchProducts
    );

    if (
      isEmpty(availableProductResponse) ||
      isEmpty(availableProductResponse.sections)
    ) {
      yield put(
        actions.app.enqueueSnackbar({
          message: "Empty products returned",
          variant: "warning",
          duration: 5000,
        })
      );
    }

    yield put(actions.app.fetchProducts.success(availableProductResponse));
  } catch (error) {
    yield put(
      actions.app.enqueueSnackbar({
        message: "Failed to fetch products",
        variant: "error",
        duration: 5000,
      })
    );
    yield put(actions.app.fetchProducts.failure(error as Error));
  }
}
