import { VariantType } from "notistack";
import { Action } from "re-reduced";
import { RequestState } from "lib/types";

export const PRODUCT_TYPE = {
  Product: "product",
  CustomisationPermanent: "customisation-permanent",
  CustomisationTemporary: "customisation-temporary",
} as const;

export type AppActivityState = "Inactive" | "Active";

export type OnlineStatusChangeReason = "Inactive" | "Cleaning" | "Maintainance";

export type TabRouteName =
  | "/"
  | "/collection"
  | "/history"
  | "/stockmanager"
  | "/stockmanager/chilleddrinks"
  | "/stockmanager/hotdrinks";

export interface AppState {
  isBootstrapped: boolean;
  isSiteOnline: boolean;
  isAlertEnabled: boolean;
  hasUpdates: boolean;
  onlineStatusChangeReason: OnlineStatusChangeReason | null;
  snacks: SnackbarItem[];
  currentTabRoute: TabRouteName;
  disabledItems: DisabledItem[];
  requests: AppRequestsState;
  remoteConfig: RemoteConfig;
  disabledCustomisationItems: DisabledCustomisationItem[];
  availableProducts: AvailableProduct[];
}

export interface AppRequestsState {
  disabledItems: RequestState;
  disabledCustomisationItems: RequestState;
  availableProducts: RequestState;
}

export interface SnackbarItem {
  id?: string;
  message: string;
  variant?: VariantType;
  action?: {
    label: string;
    dispatchableAction: Action<any>;
  };
  collapsedMessage?: string;
  duration?: number;
  persist?: boolean;
  clearPersistedSnacks?: boolean;
}

export interface SiteAvailability {
  online: boolean;
  reason?: OnlineStatusChangeReason;
}

export interface DisabledItem {
  productId: string;
  since: string;
}

export interface DisabledCustomisationItem {
  productId: string;
  isTemporary: boolean;
  since: string;
}

export interface InventoryStateResponse {
  items: DisabledItem[];
}

export interface CustomisationStateResponse {
  items: DisabledCustomisationItem[];
}

export type LogEventKind =
  | "Error"
  | "Warning"
  | "Info"
  | "Analytics"
  | "General";

export interface LogEvent {
  kind: LogEventKind;
  message: string;
  appVersion?: string;
  siteName?: string;
  siteId?: string;
  error?: any;
  info?: any;
  userAgent?: string;
}

export interface UpdateProductAvailabilityAction {
  productIds: string[];
  disableOrEnable: "disable" | "enable";
}

export interface UpdateCustomisationAvailabilityAction {
  productId: string;
  disableOrEnable: "disable" | "enable";
  isTemporary: boolean;
}

export interface DisableCustomisationItem {
  productId: string;
  isTemporary: boolean;
}

export interface StockManagerItem {
  label: string;
  productId: string;
}

export interface RemoteConfigValue {
  key: string;
  value: any;
}

type RemoteConfigKeys = "vegan_smoothies_enabled";
export type RemoteConfig = Record<RemoteConfigKeys, RemoteConfigValue> | null;

export interface Product {
  productId: string;
  label: string;
}

export type ProductTab = "food" | "drinks" | "drinkIngredients";
export interface AvailableProduct {
  tab: ProductTab;
  label: string;
  type: typeof PRODUCT_TYPE[keyof typeof PRODUCT_TYPE];
  products: Product[];
}

export interface ProductResponse {
  sections: AvailableProduct[];
}
