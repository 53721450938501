import React from "react";
import { Product } from "domain/core/app/types";
import ConfirmDialog from "ui/components/ConfirmDialog";

export interface ActionPendingConfirmation {
  item: Product;
  disableOrEnable: "enable" | "disable";
  type?: "stock" | "customisation";
}

interface ModalProps {
  actionPendingConfirmation?: ActionPendingConfirmation;
  onClose: () => void;
  onConfirm: () => void;
}
export const ConfirmChangeStockAvailabilityModal = (props: ModalProps) => {
  const willDisable =
    props.actionPendingConfirmation?.disableOrEnable === "disable";

  return (
    <ConfirmDialog
      open={Boolean(props.actionPendingConfirmation)}
      title={`${willDisable ? "Disable" : "Enable"} ${
        props.actionPendingConfirmation?.item.label
      }?`}
      cancelLabel="CANCEL"
      confirmLabel={"CONFIRM"}
      onClose={props.onClose}
      onCancel={props.onClose}
      onConfirm={props.onConfirm}
    >
      This will {props.actionPendingConfirmation?.disableOrEnable} customers{" "}
      {willDisable ? "from " : "to "}
      order{willDisable && "ing"} {props.actionPendingConfirmation?.item.label}{" "}
      via Z App.
    </ConfirmDialog>
  );
};
