import { createActions } from "re-reduced";

import { RouteKind } from "domain/core/configuration";
import {
  SiteAvailability,
  SnackbarItem,
  LogEvent,
  AppActivityState,
  TabRouteName,
  InventoryStateResponse,
  UpdateProductAvailabilityAction,
  RemoteConfig,
  CustomisationStateResponse,
  UpdateCustomisationAvailabilityAction,
  ProductResponse,
} from "domain/core/app/types";

export default createActions("CORE/APP", (create) => ({
  /**
   * Bootstrap, to be fired on app initialisation
   */
  bootstrap: create.asyncAction<void>(),
  /**
   * Fetches the site preorder status
   */
  fetchPreorderStatus: create.asyncAction<SiteAvailability>(),
  /**
   * Updates the site preorder status
   *
   * @param {SiteAvailability} payload - site availability payload
   * @example
   *  actions.updatePreorderStatus({ online: true })
   */
  updatePreorderStatus: create.asyncAction<
    SiteAvailability,
    SiteAvailability
  >(),
  /**
   * Updates the application status
   *
   * @param {AppActivityState} payload
   */
  updateActivityState: create.action<AppActivityState>(),
  /**
   * Adds a snackbar message to the queue
   */
  enqueueSnackbar: create.asyncAction<SnackbarItem, SnackbarItem>(),
  /**
   * Dimisses a snackbar
   * @param {string} payload - the snack message id
   */
  dismissSnackMessage: create.action<string>(),
  /**
   * Navigates to one of the know routes
   * @param {RouteKind} payload - one of "orders", "collection" or "history"
   */
  navigate: create.action<RouteKind>(),
  /**
   * Logs an event to Sumologic
   *
   * @param {LogEvent} payload - the event to be logged
   * @example
   *  actions.logEvent({ kind: "Error", message: "Something went wrong"})
   */
  logEvent: create.asyncAction<void, LogEvent>(),
  /**
   * Reset application's state
   */
  reset: create.action(),
  /**
   * Application is inactive
   */
  inactive: create.action(),
  /**
   * Application was restored
   */
  restored: create.action(),
  /**
   * Check for Barista updates
   */
  checkForUpdates: create.asyncAction<boolean>(),
  /**
   * Install Update
   */
  installUpdate: create.asyncAction<boolean>(),
  /**
   * Request sound
   */
  requestSound: create.action(),
  /**
   * Updates current tab route name
   */
  updateCurrentTabRoute: create.action<TabRouteName>(),
  /**
   * Fetches the inventory (Stock Manager) state
   */
  fetchInventoryState: create.asyncAction<InventoryStateResponse>(),
  /**
   * Update the disabled items
   */
  updateProductAvailability: create.asyncAction<
    InventoryStateResponse,
    UpdateProductAvailabilityAction
  >(),
  /**
   * Fetches the customisation items state
   */
  fetchCustomisationState: create.asyncAction<CustomisationStateResponse>(),
  /**
   * Update customisation items
   */
  updateCustomisationAvailability: create.asyncAction<
    CustomisationStateResponse,
    UpdateCustomisationAvailabilityAction
  >(),
  /**
   * Fetchs the remote config
   */
  fetchRemoteConfig: create.asyncAction<RemoteConfig>(),
  /**
   * Fetchs the stock management products
   */
  fetchProducts: create.asyncAction<ProductResponse>(),
}));
