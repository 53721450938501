import React from "react";
import { CircularProgress } from "@material-ui/core";
import styled from "styled-components";

const LoadingOverlayContainer = styled.div`
  background-color: white;
  opacity: 0.7;
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  z-index: 1;
  width: 100%;
  height: 100%;
`;

interface Props {
  isLoading: boolean;
}
export const LoadingOverlay = (props: Props) => {
  if (props.isLoading) {
    return (
      <LoadingOverlayContainer>
        <CircularProgress />
      </LoadingOverlayContainer>
    );
  }

  return null;
};

export default LoadingOverlay;
