import { createReducer, reduce, fold, foldP } from "re-reduced";
import { combineReducers } from "redux";
import not from "ramda/src/not";
import reject from "ramda/src/reject";
import concat from "ramda/src/concat";
import identity from "ramda/src/identity";
import always from "ramda/src/always";

import {
  SnackbarItem,
  OnlineStatusChangeReason,
  TabRouteName,
  DisabledItem,
  AppRequestsState,
  RemoteConfig,
  DisabledCustomisationItem,
  AvailableProduct,
} from "domain/core/app/types";
import actions from "domain/core/app/actions";
import { createRequestReducer } from "lib/reducers";

interface AppState {
  isBootstrapped: boolean;
  isSiteOnline: boolean;
  isAlertEnabled: boolean;
  hasUpdates: boolean;
  onlineStatusChangeReason: OnlineStatusChangeReason | null;
  snacks: SnackbarItem[];
  currentTabRoute: TabRouteName;
  disabledItems: DisabledItem[];
  remoteConfig: RemoteConfig;
  disabledCustomisationItems: DisabledCustomisationItem[];
  availableProducts: AvailableProduct[];
}

const INITIAL_STATE: AppState = {
  isBootstrapped: false,
  isSiteOnline: true,
  isAlertEnabled: true,
  hasUpdates: false,
  onlineStatusChangeReason: null,
  snacks: [],
  currentTabRoute: "/",
  disabledItems: [],
  remoteConfig: null,
  disabledCustomisationItems: [],
  availableProducts: [],
};

export const isBootstrapped = createReducer<boolean>(
  [
    reduce(actions.bootstrap.success, always<boolean>(true)),
    reduce(actions.bootstrap.failure, always<boolean>(true)),
  ],
  INITIAL_STATE.isBootstrapped
);

export const isSiteOnline = createReducer<boolean>(
  [
    fold(actions.fetchPreorderStatus.success, (response) => response.online),
    reduce(actions.updatePreorderStatus.success, not),
  ],
  INITIAL_STATE.isSiteOnline
);

export const hasUpdates = createReducer<boolean>(
  [fold(actions.checkForUpdates.success, identity)],
  INITIAL_STATE.hasUpdates
);

export const onlineStatusChangeReason = createReducer<OnlineStatusChangeReason | null>(
  [
    fold(
      actions.updatePreorderStatus.success,
      (payload) => payload.reason ?? null
    ),
  ],
  INITIAL_STATE.onlineStatusChangeReason
);

export const snacks = createReducer<SnackbarItem[]>(
  [
    foldP(actions.enqueueSnackbar.success, (payload) => concat([payload])),
    foldP(actions.dismissSnackMessage, (payload) =>
      reject<SnackbarItem, "array">((x) => x.id === payload)
    ),
  ],
  INITIAL_STATE.snacks
);

export const isAlertEnabled = createReducer<boolean>(
  [
    fold(actions.requestSound, always<boolean>(false)),
    fold(actions.bootstrap.success, always<boolean>(true)),
  ],
  INITIAL_STATE.isAlertEnabled
);

export const currentTabRoute = createReducer<TabRouteName>(
  fold(actions.updateCurrentTabRoute, identity),
  INITIAL_STATE.currentTabRoute as TabRouteName
);

export const disabledItems = createReducer<DisabledItem[]>(
  [
    fold(
      [
        actions.fetchInventoryState.success,
        actions.updateProductAvailability.success,
      ],
      (payload) => payload.items
    ),
  ],
  INITIAL_STATE.disabledItems
);

export const disabledCustomisationItems = createReducer<
  DisabledCustomisationItem[]
>(
  [
    fold(
      [
        actions.fetchCustomisationState.success,
        actions.updateCustomisationAvailability.success,
      ],
      (payload) => payload.items
    ),
  ],
  INITIAL_STATE.disabledCustomisationItems
);

export const remoteConfig = createReducer<RemoteConfig>(
  fold(actions.fetchRemoteConfig.success, identity),
  INITIAL_STATE.remoteConfig
);

export const availableProducts = createReducer<AvailableProduct[]>(
  [fold(actions.fetchProducts.success, (payload) => payload.sections)],
  INITIAL_STATE.availableProducts
);

export const requests = combineReducers<AppRequestsState>({
  disabledItems: createRequestReducer(actions.updateProductAvailability),
  disabledCustomisationItems: createRequestReducer(
    actions.updateCustomisationAvailability
  ),
  availableProducts: createRequestReducer(actions.fetchProducts),
});

export default combineReducers({
  isBootstrapped,
  isSiteOnline,
  isAlertEnabled,
  hasUpdates,
  onlineStatusChangeReason,
  snacks,
  currentTabRoute,
  disabledItems,
  requests,
  remoteConfig,
  disabledCustomisationItems,
  availableProducts,
});
